import useClipboard from "vue-clipboard3"
import { weichatTools } from '@/utils/weichatShare.js';
import QrcodeVue from 'qrcode.vue';
import {
    getweixininfo
} from '@/utils/user.js';
export default {
    components: { QrcodeVue },
    name: "InviteFriend",
    data() {
        return {
            shareShow: false,
            inviteCode: "",
            qrcodeText: "",
            qrContent: '',
            size: 220
        }
    },
    created() {
        weichatTools.wxConfigFunc();
        this.getInfo()
    },
    methods: {
        shareInit() {
            setTimeout(() => {
                const imgUrl = 'http://wx.linglong-goods.com/shareImg/share.jpg';
                weichatTools.share.shareFunc('这里可以找到具有想性价比的日常好物！快来看看~', '灵龙惠生活“理性种草寻好货”', this.qrContent, imgUrl);
            }, 500)
        },
        async shareLink() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(this.qrContent)
                this.$toast.success("已复制邀请链接")
            } catch (e) {
                console.error(e)
            }
        },
        getInfo() {
            let data = {
                memberId: this.$cookies.get('memberId')
            };
            getweixininfo(data).then(res => {
                if (res && res.code == "0") {
                    const resData = res.data || {}
                    this.inviteCode = resData.invitationCode || ""
                    this.qrContent = 'https://wx.linglong-goods.com/yllService/api/menu/personal?name=register&inviteCode=' + this.inviteCode
                    this.shareInit()
                }
            })
        },
        shareFun() {
            this.shareShow = true
        },
        async copyFun(val) {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(val)
                this.$toast.success("已复制邀请码")
            } catch (e) {
                console.error(e)
            }
        }
    }
}