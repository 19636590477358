<template>
    <div class="invite-friend">
        <div class="header">
            <div class="header-top flex align-center">
                <p class="header-top-left">我的邀请码</p>
                <div class="header-top-right flex align-center">
                    <p>{{ inviteCode }}</p>
                    <img src="@/assets/img/user/inviteFriend/copy.png" alt="" @click="copyFun(inviteCode)">
                </div>
            </div>
            <div class="header-bottom">
               <p class="header-bottom-title"><span>邀请方式</span></p>
               <div class="desc flex flex-center">
                   <span>1. 复制邀请码给好友，登录“灵龙惠生活”注册时候填写邀请码<br>2. 转发邀请海报，好友用扫码登录“灵龙惠生活”注册时确认/填写邀请码</span>
                </div>
            </div>
        </div>
        <div class="main">
            <div id="qrcode" class="flex flex-center align-center">
                <qrcode-vue :value="qrContent" :size="size" level="H" />
            </div>
            <div class="code flex align-center flex-center"><p></p>邀请码<p></p></div>
            <p class="code-text">{{ inviteCode }}</p>
        </div>
        <div class="invite-fixed flex">
            <div class="i-box flex flex-center">
                <div class="i-box-item" @click="shareLink">
                    <img src="@/assets/img/user/inviteFriend/link.png" alt="">
                    <p>邀请链接</p>
                </div>
                <!-- <div class="i-box-item" @click="shareFun">
                    <img src="@/assets/img/user/inviteFriend/poster.png" alt="">
                    <p>分享海报</p>
                </div> -->
            </div>
        </div>
        <van-popup
            v-model:show="shareShow"
            position="center"
            :style="{ width: '100%' }"
            :close-on-click-overlay="false"
            class="share-popup"
        >
            <div class="share-fixed">
                <div class="arrive flex align-center flex-center"><p></p>分享到<p></p></div>
                <div class="i-box flex flex-between">
                    <div class="i-box-item">
                        <img src="@/assets/img/user/inviteFriend/download.png" alt="">
                        <p>生成海报</p>
                    </div>
                    <div class="i-box-item">
                        <img src="@/assets/img/user/inviteFriend/weixin.png" alt="">
                        <p>微信</p>
                    </div>
                    <div class="i-box-item">
                        <img src="@/assets/img/user/inviteFriend/qq.png" alt="">
                        <p>QQ</p>
                    </div>
                    <div class="i-box-item">
                        <img src="@/assets/img/user/inviteFriend/wp.png" alt="">
                        <p>朋友圈</p>
                    </div>
                    <div class="i-box-item">
                        <img src="@/assets/img/user/inviteFriend/qq-k.png" alt="">
                        <p>QQ空间</p>
                    </div>
                </div>
                <p class="cancel" @click="shareShow = false">取消</p>
            </div>
        </van-popup>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.share-popup {
    width: 100% !important;
    bottom: 0 !important;
    transform: unset !important;
    background: none !important;
    left: 0;
    top: unset !important;
    background: #FFFFFF !important;
    -webkit-box-shadow: 0px 4px 20px 0px rgba(208, 211, 217, 0.3);
    box-shadow: 0px 4px 20px 0px rgba(208, 211, 217, 0.3);
    -webkit-border-radius: 20px 20px 0px 0px;
    border-radius: 20px 20px 0px 0px;
    .share-fixed{
        margin-top: 15px;
        .arrive{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            p{
              width: 20px;
              height: 1px;
              background: #CCCCCC;
            }
            p:nth-child(1) {
                margin-right: 15px;
            }
            p:nth-child(2) {
                margin-left: 15px;
            }
        } 
        .i-box{
            width: 312px;
            margin: 20px auto 0;
            .i-box-item{
                width: 48px;
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    width: 48px;
                    height: 48px;
                    margin-bottom: 5px;
                }
                p{
                    height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ABB0BD;
                    line-height: 17px;
                    white-space: nowrap;
                }
            }
        }   
        .cancel{
            width: 314px;
            height: 40px;
            background: #F6F6F6;
            -webkit-border-radius: 22px;
            border-radius: 22px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            text-align: center;
            line-height: 40px;
            margin: 20px auto 15px;
        }
    }
}
</style>